.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

}

.App-content {
  flex-grow: 1;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 25vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.App-footer {
  background-color: #f2f2f2;
  padding: 20px;
  text-align: center;
  font-size: 18px;
  color: #666;
}

.App-footer-links {
  margin-top: 10px;
}

.App-footer-links a {
  margin: 0 10px;
  color: #888;
  text-decoration: none;
}

.App-footer-links a:hover {
  text-decoration: underline;
}

.App-welcome {
  font-size: 50px;
  font-family: "Arial", sans-serif;
}

@media (max-width: 768px) {
  .App-welcome {
    font-size: 18px;
  }

  h2 {
    font-size: 26px;
    margin-bottom: 10px;
  }

  p {
    font-size: 18px;
    line-height: 1.5;
  }

  .about-section {
    margin-bottom: 40px;
    font-size: 4em;
    text-align: left;
    margin-left: 20vh;
  }
}

.social-icons a {
  margin-right: 10px;
  margin-top: 20;
  color: #888;
  font-size: 30px;
  text-decoration: none;
}

.social-icons a:hover {
  color: #555;
}

.homepage {
  text-align: left;
  padding: 20px;
  background: linear-gradient(to bottom, #1f5cce, #cfdef3);
  /* Dégradé de couleurs */

  padding: 20px;
}

.hero-section {
  margin-bottom: 30px;
}

.illustration {
  width: 300px;
  height: 300px;
  border-radius: 50%;
}

.about-section {
  margin-bottom: 40px;
  font-size: 4em;
  text-align: left;
  margin-left: 20px;
  margin-right: 20px;
}

h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

h2 {
  font-size: 36px;
  margin-bottom: 10px;
}

p {
  font-size: 24px;
  line-height: 1.5;
}

/* Produits */

.product-preview {
  width: 300px;
  padding: 20px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease;
}

.product-preview:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.product-image {
  width: 100%;
  border-radius: 10px;
}

.product-name {
  margin-top: 10px;
  font-size: 18px;
}

.product-description {
  margin-top: 5px;
  font-size: 14px;
}